import * as React from "react";
import { styled } from "@mui/material/styles";

export default function Spinner() {
  const StyledSpinner = styled("div")({
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backdropFilter: " blur(5px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "12000",
    "& .spinner": {
      width: "60px",
      height: "60px",
      position: "relative",

      "& .dot": {
        position: "absolute",
        inset: 0,
        display: "flex",
        justifyContent: "center",
        "&::after": {
          content: '""',
          width: "7px",
          height: "7px",
          borderRadius: "50%",
          backgroundColor: "rgb(12, 180, 231)",
        },
        animation: "spin 2s infinite",
      },
      "& .dot:nth-of-type(2)": {
        animationDelay: "100ms",
      },
      "& .dot:nth-of-type(3)": {
        animationDelay: "200ms",
      },
      "& .dot:nth-of-type(4)": {
        animationDelay: "300ms",
      },
      "& .dot:nth-of-type(5)": {
        animationDelay: "400ms",
      },
      "@keyframes spin": {
        to: {
          transform: "rotate(360deg)",
        },
      },
    },
  });
  return (
    <StyledSpinner>
      <div className="spinner">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </StyledSpinner>
  );
}
