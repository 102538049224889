import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button, Grid, MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from 'react';
import { Failed, Success } from 'common_components/toaster/toaster';
import { commonService } from 'helpers/common.service';
import SaveIcon from '@mui/icons-material/Save';
import { Box } from '@mui/system';
import Spinner from 'common_components/PageLoader';

interface SettingType {
  default_model: {
    value: string;
  };
  llmmodel: {
    ischecked: boolean;
    value: string;
  }[];
}

export default function General() {
  const [projectFilter, setProjectFilter] = useState<string>('');
  const [dropDown, setDropDown] = useState([]);
  const [models, setModels] = useState([]);
  const [save, setSave] = useState([]);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
  const [generalSettings, setGeneralSettings] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  const formSave = () => {
    if(projectFilter && selectedValue){
      const payload = {
        "json_data":{
          "llmmodel": [
            {
              "value": "chatgpt",
              "ischecked": selectedValue == 'chatgpt' ? true : false
            },
            {
              "value": "llama",
              "ischecked": selectedValue == 'llama' ? true : false
            }
          ],
          "default_model": {
            "value": projectFilter
          }
        }
      };
      updateChartModel(payload)
    }
  };

  const handleRadioChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const updateProjectFilter = (val:any) => {
    setProjectFilter(val.value);
  };

  async function getGeneralSettings() {
    try {
      setLoader(true);
      let url = {
        settings: "/get-settings",
        model: "/data_model",
      };
      // Using Promise.all to make concurrent requests
      const [settingsResponse, modelResponse] = await Promise.all([
        commonService.getSettingsDropDown(url.settings),
        commonService.getSettingsDropDown(url.model),
      ]);
      // Extract data from responses
      const settingsData = settingsResponse.data?.data;
      const modelData = modelResponse.data?.data;
      let checked:any = settingsData[0].llmmodel.find((checked:any) => checked?.ischecked);
      setSelectedValue(checked?.value);
      // Update state variables
      setGeneralSettings(settingsData);
      setDropDown(modelData);
      setModels(settingsData[0].llmmodel);
      setProjectFilter(settingsData[0].default_model.value);
    } catch (error: any) {
      Failed(error.response.data.message);
    } finally {
      setLoader(false);
    }
  }

  async function updateChartModel(data: any){
    try {
      setLoader(true);
      let url = '/settings';
      let response = await commonService.updateModel(url, data);
      if(response.status == 200){
        Success(response.data.message ?? 'Settings updated successfully');
        getGeneralSettings();
      }
    } catch (error: any) {
      Failed(error.response.data.message);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getGeneralSettings();
  },[]);


  return (
    <Grid item >
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', width:'400px', margin:'20px 0px' }}>
        <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize:'20px' }}>LLM Model</FormLabel>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={selectedValue || ''}
                onChange={handleRadioChange}
              >
              <Box sx={{ display:'flex'}}>
                 {models?.map((p: any, ind: number) => (
                      <FormControlLabel value={p.value} control={<Radio />} label={p.value} checked={selectedValue === p.value}/>
                  ))}
              </Box>
            </RadioGroup>
      </FormControl>
      </Box>
      <Box  sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', width:'400px',margin:'20px 0px'  }}>
        <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize:'20px' }}>Default Model</FormLabel>
          <FormControl sx={{ minWidth: "11rem" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    updateProjectFilter(e?.target);
                  }}
                  value={projectFilter}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  {dropDown?.map((p: any, ind: number) => (
                    <MenuItem key={ind} value={p?.category}>
                      {p?.category}
                    </MenuItem>
                  ))}
              </Select>
        </FormControl>
      </Box>
      <Button
        variant="contained"
        size="large"
        startIcon={<SaveIcon />}
        onClick={formSave}
      >
        Save
      </Button>
     { loader && <Spinner /> }
    </Grid>
  )
}
