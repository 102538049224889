import axios from "axios";
import { Warning } from "common_components/toaster/toaster";
  axios.defaults.baseURL= process.env.REACT_APP_API_URL; 
  export const setAxiosDefauls = () => {
  axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);
  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
  };

  const onRequestFulfilled = (config:any) => {
  let token = localStorage.getItem("token");
  if (token && token !== "undefined") {
    config.headers = {
      "x-api-key": process.env.REACT_APP_SECRET_KEY,
      Authorization: "Bearer " + token,
    };
  } else
    config.headers = {
      "x-api-key": process.env.REACT_APP_SECRET_KEY,
    };

  return Promise.resolve(config);
  };

  const onRequestRejected = (error:any) => {
  return Promise.reject(error);
  };

  const onResponseFulfilled = (config:any) => {
  return Promise.resolve(config);
  };

  const onResponseRejected = (error:any) => {
  // Error StatusCode 401 , 403, 422
  const error_status_code = [401, 403, 422];
  if (error_status_code.includes(error?.response?.status)) {
    localStorage.clear();
    if (error?.response?.status == "401") {
      Warning("Session logged out, please login again");
    }
    window.location.href = "/login";
  }

  return Promise.reject(error);
  };
