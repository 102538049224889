import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import "./AskQuestion.scss";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  outlinedInputClasses,
} from "@mui/material";
import { commonService } from "helpers/common.service";
import TypingLine from "./TypingLine";
import AgiraLogo from "asset/images/agirasurelogo.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useReactToPrint } from "react-to-print";
import Spinner from "common_components/PageLoader";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Modal from '@mui/material/Modal';
import SearchIcon from "@mui/icons-material/Search";
import {
  createTheme,
  ThemeProvider,
  Theme,
  useTheme,
} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Failed } from "common_components/toaster/toaster";


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 4,
  px: 4,
  pb: 3,
};

const AskQuestionSession = () => {
  const [content, setContent] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [err, setErr] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [claimNoerror, setClaimNoError] = useState<any>(false);

  const [helperText, setHelperText] = useState("");
  const [modalhelperText, setModalHelperText] = useState("");

  const [disablesubmit, setdisableSubmit] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const [alignment, setAlignment] = useState <any>('open-session');
  const [modal,setModal] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false);
  const [claimNo, setClaimno] = useState<any>()
  const [addclaimNo,setAddClaimno] = useState<any>()
  const [search, setSearch] = useState("");
  const [projectFilter, setProjectFilter] = useState<string>('');
  const [allProjects, setAllProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [project, setProject] = useState<any>([]);
  const [generalSettings, setGeneralSettings] = useState<any>([]);
  const [dropDown, setDropDown] = useState([]);


  const handleClose = () => {
    setModal(false);
  };
  const handleChange = (
    event: any,
    newAlignment: string,
  ) => {
    // unclicked not allowed default one value checked
    setAlignment(event.target.value);
    setError(false)
    setHelperText("")
    if(event.target.value === "enquire-claim" ){
      setModal(true)
    }
    // if(event.target.value !== "enquireclaim"){
      setClaimno("")
      setAddClaimno("")
      setContent([])
    // }
  };
  const theme = useTheme();
  const componentRef = useRef<any>();
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (error) {
      return;
    }
    setText("");
    try {
      // Store the current question in the content state
      setContent((prevContent: any) => [
        ...prevContent,
        { question: text, answer: "" }, 
      ]);

      // Make the API request
      const response = await commonService.postServiceSeperateBaseUrl(`${alignment}`, {
        question: addclaimNo ? `${text}+${addclaimNo}` : text,
        token: commonService.chatToken
      });
      // if (response && response?.data && response?.data?.data) {
        if (response && response?.data) {
        // const message = response?.data?.data?.join("");
        const message = response?.data;
        setContent((prevContent: any) =>
          prevContent?.map((item: any) =>
            item.question === text ? { ...item, answer: alignment === "enquire-claim" ? message.data : message.data?.airesponse } : item
          )
        );
      } else {
        console.error("Invalid response format");
      }
      // console.log(message);
      // Update the answer in the content state

      // Clear the input field
    } catch (err:any) {
      console.log(err);
      let msg = err.response.status;
      if(msg === 400){
        setContent((prevContent: any) =>
        prevContent?.map((item: any) =>
          item.question === text ? { ...item, answer: err.response.data.error } : item
        )
      )
      }
      setErr("Oops! Something Went Wrong !");
    }
  };
  const onBeforeGetContentResolve = React.useRef<any>(null);
  React.useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === "function") {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoader(true);

    // setText("Loading new text...");

    return new Promise((resolve: any) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoader(false);
        // setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoader]);
  const handlePrintError = useCallback((e: any) => {
    console.log("Error print", e); // tslint:disable-line no-console
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    documentTitle: "title",
    suppressErrors: true,
    onBeforeGetContent: handleOnBeforeGetContent,
    onPrintError: handlePrintError,
  });
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setText(inputValue);
    // Split the input into words and count the number of words
    const wordCount = inputValue
      .split(/\s+/)
      .filter((word) => word !== "").length;
    // Validate that there are at least 4 words
    if (wordCount < 3) {
      setdisableSubmit(false);
      setError(true);
      setHelperText("Please enter at least 3 words.");
    } else {
      setdisableSubmit(true);

      setError(false);
      setHelperText("");
    }
  };
  const handleAdd = ()=>{
    if(claimNo){
      setAddClaimno(claimNo)
      setModal(false)
      setClaimNoError(false)
      setModalHelperText("")
    }else{
      setClaimNoError(true)
      setModalHelperText("*Please enter a claim Number")
    }
  }
  React.useEffect(() => {
    if (content.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    getGeneralSettings();
  }, [content.length]);


  const latestMessage = content[content.length - 1];
  const renderLatestMessage = latestMessage && (
    <TypingLine
      key={content.length - 1}
      question={latestMessage.question}
      fact={latestMessage.answer}
      // err={err}
      isLatest={true} // Since it's the latest message
    />
  );

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  const updateProjectFilter = (val: any) => {
    setProjectFilter(val);
  };
 
  const selectedProjectNames: string[] = selectedProjects.map((projectId) => {
    const project: any = allProjects.find((p: any) => p.id === projectId);
    return project ? project.name : ""; // Return the project name if found, empty string otherwise
  });

  const handleProjectChange = (event: any) => {
    // setProject(event.target.value as string);
    const {
      target: { value },
    } = event;
    setProject(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setSelectedProjects(typeof value === "string" ? value.split(",") : value);
  };

  async function getGeneralSettings() {
    try {
      setLoader(true);
      let url = {
        settings: "/get-settings",
        model: "/data_model",
      };
      // Using Promise.all to make concurrent requests
      const [settingsResponse, modelResponse] = await Promise.all([
        commonService.getSettingsDropDown(url.settings),
        commonService.getSettingsDropDown(url.model),
      ]);
      // Extract data from responses
      const settingsData = settingsResponse.data?.data;
      const modelData = modelResponse.data?.data;
      // Update state variables
      setGeneralSettings(settingsData);
      setDropDown(modelData);
      setProjectFilter(settingsData[0].default_model.value);
    } catch (error: any) {
      Failed(error.response.data.message);
    } finally {
      setLoader(false);
    }
  }


  return (
    <Box className="askquestionContainer">
      {loader && <Spinner />}

      {content.length <= 0 ? (
        !addclaimNo  &&<Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
            margin: 0,
            fontSize: "30px",
            fontWeight: 700,
            opacity: 0.5,
          }}
        >
          {/* <img src={AgiraLogo} alt="agiralogo" width="60px" height="60px" /> */}
          How can I help today?
        </Box>
      ) : (
        <Button
          sx={{
            background: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              background: theme.palette.secondary.light,
            },
            position: "absolute",
            right: "2rem",
            marginTop: "1rem",
          }}
          onClick={handlePrint}
        >
          Export
        </Button>
      )}

      <Box ref={componentRef}>
        <Box className="chatContent">
        {content.slice(0, content.length - 1).map((line: any, index: number) => (
        <TypingLine
          key={index}
          question={line.question}
          fact={line.answer}
          err={err}
          isLatest={false} // Since it's not the latest message
        />
      ))}
  {renderLatestMessage}
          
          <div ref={ref} />
        </Box>
      </Box>
      {/* {addclaimNo && <h3 style={{textAlign:"center", position:"absolute", top:"80%", left:"50%", border:"1px solid black", padding:"10px"}}>Claim No: {addclaimNo}</h3>} */}
      {addclaimNo &&  <Chip sx={{textAlign:"center", position:"absolute", top:"81.5%", left:"50%",fontSize:"15px",padding:"25px"}} label={`Claim No : ${addclaimNo}`} variant="outlined" />}
      
      <FormControl
        onSubmit={(e) => handleSubmit(e)}
        component={"form"}
        sx={{
          position: "absolute",
          bottom: "20px",
          display: "flex",
          justifyContent: "center",
          width: "80%",
        }}
      >
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{padding:"20px",marginLeft: "20px",}}
      >
      <ToggleButton value="open-session">Open Session</ToggleButton> 
      <ToggleButton value="enquire-claim">Enquire Claim</ToggleButton>
      <ToggleButton value="chat">Chat</ToggleButton>
      </ToggleButtonGroup>
        <FormControl sx={{ minWidth: "11rem" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  updateProjectFilter(e?.target?.value);
                }}
                value={projectFilter}
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {dropDown?.map((p: any, ind: number) => (
                  <MenuItem key={ind} value={p?.category}>
                    {p?.category}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Box
          sx={{
            display: "flex",
            marginLeft: "40px",
            gap: "10px",
          }}
        >
        
          <TextField
            id="name"
            onChange={handleTextChange}
            value={text}
            placeholder="Ask a Question"
            fullWidth
            autoComplete="off"
            error={error}
            // helperText={helperText}
            disabled = {alignment === "enquire-claim" ? (claimNo ? false : true) : false}
            sx={{justifyContent:"center"}}
          />
          <Button
            type="submit"
            sx={{
              background:
                text && disablesubmit ? theme?.palette?.primary?.main : "grey",
              color: "white",
              // width: "6rem",
              // marginLeft: "10px",
              "&:hover": {
                background: theme?.palette?.primary?.main,
              },
            }}
            disabled={text && disablesubmit ? false : true}
          >
            <ArrowUpwardIcon />
          </Button>

          <Button
            sx={{
              background: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                background: theme.palette.secondary.light,
              },
            }}
            onClick={() => {
              setContent([]);
              setError(false);
              setHelperText("");
              setText("")
            }}
          >
            Clear
          </Button>
        </Box>
        <p style={{ marginLeft: "40px", color: "red" }}>{helperText}</p>
      </FormControl>

       <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
        <Typography id="spring-modal-title" variant="h3" component="h2">
              Add Claim Number
            </Typography>
            &nbsp;&nbsp;
            &nbsp;        <TextField
            id="name"
            onChange={(e:any)=>setClaimno(e.target.value)}
            value={claimNo}
            placeholder="Enter the claim number"
            fullWidth
            autoComplete="off"
            error={claimNoerror}
            // helperText={helperText}
          />
          <p style={{ marginLeft: "40px", color: "red" }}>{modalhelperText}</p>

          <Button onClick={handleAdd} sx={{background:"green",color:"white",marginTop:"10px", "&:hover" : {background : "green"}}}> Add</Button>
          <Button onClick={handleClose} sx={{marginTop:"10px"}}> Close</Button>

        </Box>
      </Modal>
    </Box>
  );
};

export default AskQuestionSession;
