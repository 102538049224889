import { Box, Button, FormControl, Grid, MenuItem, Select } from '@mui/material'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useRef, useState } from 'react';
import { commonService } from 'helpers/common.service';
import { Failed, Success } from 'common_components/toaster/toaster';
import FormLabel from '@mui/material/FormLabel';
import FormWithArray from './FormArray';
import Spinner from 'common_components/PageLoader';
export default function Template() {
  const [allTemplate, setAllTemplate] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [model,setModel] = useState('');
  const [defaultModelName, setDefaultModelName] = useState<string>('');
  const [filterChat, setFilterChat] = useState<any>([]);
  const [template,setTemplate] = useState([]);
  const [showTemplate, setShowTemplate] = useState([]);
  const initialRef: any = null;
  const childRef = useRef(initialRef);
  const [childData, setChildData] = useState<any>();
  
  const [types, setTypes] = useState<any>(true);

  const [loader, setLoader] = useState(false);
  let transformedObject:any = {};
  const setTemplateData = (newData:any[]) => {
    transformedObject = newData.reduce((result, item) => {
      result[item.value] = item.textArea;
      return result;
    }, {});
    setChildData(transformedObject);
  };

  const formSave = () => {
    if (childRef.current) {
      // Call the child method using the ref
        childRef.current.savedData();
    }
    const payload = {
      "data_model_name": defaultModelName,
      "chat_type": model,
      "prompt_template": transformedObject
    };
    updateTemplates(payload);
    setTypes(false)
    
  };

  const addTemplate = () => {
   // Check if the child component instance exists
   if (childRef.current) {
    // Call the child method using the ref
      childRef.current.addFormField();
    }
  };

  async function updateTemplates(data:any) {
    try {
      setLoader(true);
      const url = "/templates";
      const response = await commonService.updateModel(url,data);
      Success(response.data.message);
      getGeneralSettings();
    } catch (error: any) {
      Failed(error.response.data.message);
    } finally {
      setLoader(false);
    }
  };

  async function getGeneralSettings() {
    try {
      setLoader(true);
      let url = {
        settings: "/get-settings",
        template: "/get-templates",
      };
  
      const [settingsResponse, templateResponse] = await Promise.all([
        commonService.getSettingsDropDown(url.settings),
        commonService.getTemplate(url.template),
      ]);
  
      const template = templateResponse?.data?.data;
      const settingsData = settingsResponse?.data?.data;
  
      setAllTemplate(template);
      setModelName(template.map((id: any) => ({ data_model_name: id.data_model_name })));
      if(types){
        setDefaultModelName(settingsData[0].default_model.value);
      }
    } catch (error: any) {
      Failed(error.response);
    } finally {
      setLoader(false);
    }
  }
  
  useEffect(() => {
    getGeneralSettings();
  }, []);
  
  useEffect(() => {
    // Use the updated value of allTemplate to get chartTypes
    const chartTypes = getChatType(allTemplate);
    setFilterChat(chartTypes);
    if(types){
      setModel('');
    }
    // setModel(chartTypes[0]?.chat_type);
  }, [allTemplate,defaultModelName,types]);
  

  const updateChatTypeFilter = (val: any) => {
    const filterChatUpdated = filterChat.filter((type:any) => type.chat_type == val)
    setShowTemplate(filterChatUpdated[0].prompt_template);
    setModel(val);
  };

  const updateProjectFilter = (val: any) => {
    setDefaultModelName(val);
    setTypes(true);
  };
  
  function getChatType(template: any) {
    const filterChartType = template.find((id: any) => id.data_model_name === defaultModelName);
  
    if (filterChartType) {
      return filterChartType.chat_type;
    }
  
    return [];
  }

  return (
    <Grid item >
      <Box sx={{ display:'flex', alignItems:'flex-end', justifyContent:'space-between', width:'800px',margin:'20px 0px'  }}>
        <Box sx={{ display:'flex', flexDirection:'column'}}>
        <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize:'20px', margin:'20px 0px' }}>Model Name</FormLabel>
          <FormControl sx={{ minWidth: "11rem" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e:any) => {
                    updateProjectFilter(e?.target?.value);
                  }}
                  value={defaultModelName}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  {modelName?.map((p: any, ind: number) => (
                    <MenuItem key={ind} value={p?.data_model_name}>
                      {p?.data_model_name}
                    </MenuItem>
                  ))}
              </Select>
        </FormControl>
        </Box>
        <Box  sx={{ display:'flex', flexDirection:'column'}}>
          <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize:'20px', margin:'20px 0px' }}>Chart Type</FormLabel>
          <FormControl sx={{ minWidth: "11rem" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    updateChatTypeFilter(e?.target?.value);
                  }}
                  value={model}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  {filterChat?.map((p: any, ind: number) => (
                    <MenuItem key={ind} value={p?.chat_type}>
                      {p?.chat_type}
                    </MenuItem>
                  ))}
              </Select>
        </FormControl>
        </Box>
        {/* <Box  sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}> */}
          <Button
            variant="contained"
            size="large"
            startIcon={<SaveIcon />}
            onClick={addTemplate}
            disabled={!model}
          >
            Add Prompt Template
          </Button>
          <Button
            variant="contained"
            size="large"
            startIcon={<SaveIcon />}
            onClick={formSave}
            disabled={!model}
          >
            Save
          </Button>
        {/* </Box> */}
      </Box>
     { !!model &&  <FormWithArray ref={childRef} passTemplateData={setTemplateData} template={showTemplate}/> }
     

      { loader && <Spinner /> }
    </Grid>
  )
}
