import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "./App.css";
import NavigationScroll from "./container/layout/NavigationScroll";
import RoutePath from "./routes";
import themes from "./asset/themes";
import { setAxiosDefauls } from "helpers/interceptor";

function App() {
  useEffect(() => {
    // Function to handle 'touchstart' event
    const handleTouchStart = (event: any) => {
      // Your touch event handling code here
    };

    // Add 'touchstart' event listener with passive: true
    document.addEventListener("touchstart", handleTouchStart, {
      passive: true,
    });

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);
  const customization = useSelector((state: any) => state.customization);
  setAxiosDefauls();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <RoutePath />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
