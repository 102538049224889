import React from "react";
import "./loader.scss";
const loader = () => {
  return (
    <div className="item">
      <div className="loader-pulse"></div>
    </div>
  );
};

export default loader;
