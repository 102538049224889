import { Button, IconButton, TextField } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import TextareaAutosize from '@mui/material/TextareaAutosize';

interface FormWithArrayProps {
  passTemplateData: any; // Adjust the type according to your needs
  template: any
}

const FormWithArray = forwardRef(({ passTemplateData, template }:FormWithArrayProps ,ref) => {
  const [formFields, setFormFields] = useState([{ id: Math.random(), value: 'Hi', textArea: 'Hello'}]);

  const [storeValues, setStoreValues] = useState<any>(null);

  const handleChange = (id:any, value:any, type:string) => {
    // Update the value of the specific form field
    setFormFields((prevFields) =>
       prevFields.map((field) => {
            if(field.id === id && type == 'templatename'){
              return { ...field,  value }
              }else if(field.id === id && type == 'templatevalue'){
                return { ...field, textArea:value }
            }
            else{
              return field
            }
        })
    );
  };

  const removeFormField = (id:any) => {
    // Remove a form field
    setFormFields((prevFields) => prevFields.filter((field) => field.id !== id));
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    
    // Access form field values from the state (formFields)
    
    // Add your form submission logic here
  };

  useImperativeHandle(ref, () => ({
    addFormField(){
        // Add a new form field
        setFormFields((prevFields) => [...prevFields, { id: Date.now(), value: '', textArea:'' }]);
    },
    savedData(){
      passTemplateData(formFields);
    }
  }));
  
  useEffect(() => {
    // passTemplateData(formFields);
    const updatedFormFields = Object.keys(template).map(key => {
      return { 
            id: Math.random(),
            value: key, 
            textArea:template[key]
       }
    });
    setFormFields(updatedFormFields)
  },[template]); //formFields,template

  return (
    <form onSubmit={handleSubmit}>
      {
      formFields.length !== 0 && formFields.map((field) => (
        <Box key={field.id} sx={{ display:'flex', width:'100%' }}>
          <Box sx={{ display:'flex',alignItems:"flex-start",flexDirection:'column', width:'80%' }}>
            <TextField 
              sx={{ margin:'20px 0px' }}
              id="outlined-basic" 
              label="Template Name" 
              variant="outlined" 
              type="text"
              value={field.value}
              onChange={(e) => handleChange(field.id, e.target.value,'templatename')}
            />

            <TextareaAutosize
              value={field.textArea}
              minRows={3}
              aria-label="empty textarea"
              placeholder="Text Area"
              onChange={(e) => handleChange(field.id, e.target.value, 'templatevalue')}
              style={{ width: '100%', resize:'none',  padding:'10px'}}
            />
          </Box>
          <Box sx={{ cursor:'pointer', display:'flex', alignItems:'flex-end', marginLeft:'50px' }} onClick={() => removeFormField(field.id)}>
            <DeleteIcon color='error'/>
          </Box>
        </Box>
      ))
      }

    </form>

  );
});

export default FormWithArray;