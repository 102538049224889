import { SET_USER } from "features/theme/actions";
import { commonService } from "helpers/common.service";
import AskQuestion from "pages/askquestion/AskQuestion";
import AskQuestion_chatPdf from "pages/askquestion chatpdf/AskQuestion";
import AskQuestionSession from "pages/askquestion_session/AskQuestion";

import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import Settings from "pages/Settings/Settings";
import AskQuestionsDm from "pages/askquestion_session/AskQuestionsDm";
import ClaimSearch from "pages/askquestion_session/ClaimSearch";
import AskQuestionAgentSession from "pages/askquestion_session/AskQuestionAgentSession";

const Loader = lazy(() => import("../common_components/PageLoader"));
const PageNotFound = lazy(() => import("../pages/pagenotfound/index"));
const Login = lazy(() => import("../pages/authentication/Login"));
const ChangePassword = lazy(
  () => import("../pages/authentication/ChangePassword")
);
const ResetPassword = lazy(
  () => import("../pages/authentication/ResetPassword")
);
const InvalidToken = lazy(() => import("../pages/authentication/InvalidToken"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const UserDashboard = lazy(() => import("../pages/userDashboard/index"));
const Layout = lazy(() => import("../container/layout/MainLayout"));
const ReportPage = lazy(() => import("../pages/report/index"));
const Data = lazy(() => import("../pages/data/index"));
const ProjectsPage = lazy(() => import("../pages/projects/index"));
const UserManagementPage = lazy(() => import("../pages/user_management/index"));
const Fileupload = lazy(() => import("../pages/file/FileUpload/index"));
const FilePage = lazy(() => import("../pages/file"));
const FileModel = lazy(() => import("../pages/file/FileUpload/index"));
const NoMatch = lazy(() => import("../pages/report/nomatch/index"));
const SearchPattern = lazy(() => import("../pages/searchPattern"));
const GuestRoute = (props: any) => {
  // Setting the role as user or admin in auth
  const userRole = useSelector((state: any) => state?.user?.userData?.is_admin);
  const role = userRole ? "admin" : "user";
  const auth = userRole ? role : null;
  let location = useLocation();
  return props?.roles?.includes(auth) ? (
    <Navigate to={`/${auth}/dashboard`} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

const ProtectedAdminRoute = (props: any) => {
  const [loading, setLoading] = useState(true);
  const userRole = useSelector((state: any) => state?.user?.userData?.is_admin);
  const role = userRole ? "admin" : "user";
  const auth = userRole ? role : null;
  let location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    // Simulate an asynchronous data fetching operation (replace with your actual data fetching logic)
    const fetchData = async () => {
      // Fetch user data or dispatch an action to load it
      // For simulation purposes, we use a setTimeout to mimic an asynchronous operation
      let me_url = "/auth/me";
      let response = await commonService.getServices(me_url);

      dispatch({
        type: SET_USER,
        userDetails: response.data.data,
      });
      setTimeout(() => {
        // Set loading to false once data is fetched
        setLoading(false);
      }, 1000); // Adjust the duration based on your use case
    };

    // Call the fetchData function
    fetchData();
  }, []);
  if (loading || userRole === undefined) {
    // Loading state while user data is being fetched
    return <Loader />;
  }

  return props?.roles?.includes(auth) ? (
    <Outlet />
  ) : auth ? (
    <Navigate to={`/${auth}/dashboard`} state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const ProtectedUserRoute = (props: any) => {
  const userRole = useSelector((state: any) => state?.user?.userData?.is_admin);
  const role = userRole ? "admin" : "user";
  // console.log(role);
  console.log(props?.roles);
  const auth = role ? role : null;
  console.log(auth);
  let location = useLocation();
  return props?.roles?.includes(auth) ? (
    <Outlet />
  ) : auth ? (
    <Navigate to={`/${auth}/dashboard`} state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const RoutePath = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/">
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to="login" replace />} />
          <Route element={<GuestRoute roles={["admin", "user"]} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/change_password" element={<ChangePassword />} />
            <Route path="/reset_password" element={<ResetPassword />} />
          </Route>
          <Route path="/invalid_token" element={<InvalidToken />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route element={<Layout />}>
            <Route element={<ProtectedAdminRoute roles={["admin"]} />}>
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/report" element={<ReportPage />} />
              <Route path="/admin/data" element={<Data />} />
              <Route path="/admin/searchpattern" element={<SearchPattern />} />
              <Route path="/admin/file_upload" element={<Fileupload />} />
              <Route path="/admin/projects" element={<ProjectsPage />} />
              <Route
                path="/admin/user_management"
                element={<UserManagementPage />}
              />
              <Route path="/admin/nomatch" element={<NoMatch />} />
              <Route path="/admin/file" element={<FilePage />} />
              <Route path="/admin/q&a" element={<AskQuestion />} />
              <Route path="/admin/fflow" element={<AskQuestion_chatPdf />} />
              <Route path="/admin/session" element={<AskQuestionSession />} />
              <Route path="/admin/claim_search" element={<ClaimSearch />} />
              <Route path="/admin/admin_session" element={<AskQuestionsDm />} />
              <Route path="/admin/agent_session_demo" element={<AskQuestionAgentSession/>} />
              <Route path="/admin/setting" element={<Settings />} />
              <Route
                path="/admin/file/file_upload"
                index
                element={<FileModel />}
              />
            </Route>
            <Route element={<ProtectedUserRoute roles={["user"]} />}>
              <Route path="/user/dashboard" element={<UserDashboard />} />
              <Route path="/user/report" element={<ReportPage />} />
              <Route path="/user/file_upload" element={<Fileupload />} />
              <Route path="/user/file" element={<FilePage />} />
              <Route
                path="/user/file/file_upload"
                index
                element={<FileModel />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutePath;
