// import React, { useState, useEffect, useRef } from "react";
// import Spinner from "common_components/textLoader/loader";
// import { Avatar, Box } from "@mui/material";
// import AgiraLogo from "asset/images/agirasurelogo.png";
// import { useSelector } from "react-redux";

// const TypingLine = ({ question, fact, err, isLatest }: any) => {
//   const [displayFact, setDisplayFact] = useState("");
//   const firstName = useSelector(
//     (state: any) => state?.user?.userData?.first_name
//   );
//   const ref = useRef<HTMLDivElement>(null);
//   //console.log(firstName);
//   useEffect(() => {
//     let currentQuestion = "";
//     let currentFact = "";
//     let questionInterval: any, factInterval: any;

//     const startFactTyping = () => {
//       factInterval = setInterval(() => {
//         if (currentFact.length < fact.length) {
//           currentFact += fact[currentFact.length];
//           setDisplayFact(currentFact);
//         } else {
//           clearInterval(factInterval);
//         }
//       }, 10);
//     };

//     // startTyping();
//     startFactTyping();

//     return () => {
//       clearInterval(questionInterval);
//       clearInterval(factInterval);
//     };
//   }, [question, fact]);

//   React.useEffect(() => {
//     if (displayFact.length) {
//       ref.current?.scrollIntoView({
//         behavior: "smooth",
//         block: "end",
//       });
//     }
//   }, [displayFact.length]);

//   const htmlContent = <div dangerouslySetInnerHTML={{ __html: displayFact }} />;

//   return (
//     <Box sx={{ lineHeight: "30px" }}>
//       <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
//         <Avatar
//           sx={{
//             width: "25px",
//             height: "25px",
//             fontSize: "15px",
//             color: "white",
//           }}
//         >
//           {firstName?.charAt(0)}
//         </Avatar>

//         <span style={{ fontSize: "13px", fontWeight: 600 }}>You</span>
//       </Box>
//       <div style={{ marginBottom: "10px", marginLeft: "40px" }}>
//         <div style={{fontSize:'18px'}}>{question}</div>
//       </div>
//       <Box>
//         <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
//           <img src={AgiraLogo} alt="agiraLogo" width="30px" height="30px" />
//           <span style={{ fontWeight: 600, fontSize: "13px" }}>AgiraSure</span>
//         </div>
//         {err ? (
//           <span
//             style={{
//               color: "red",
//               // border: "1px solid red",
//               padding: "0 35px",
//               // background: "#f79992",
//             }}
//           >
//             {err}
//           </span>
//         ) : displayFact ? (
//           <div style={{ marginLeft: "40px", width: "90%",fontSize:'18px' }}>{htmlContent}</div>
//         ) : (
//           <Spinner />
//         )}{" "}
//         <div ref={ref} />
//       </Box>
//     </Box>
//   );
// };

// export default TypingLine;

import React, { useState, useEffect, useRef } from "react";
import Spinner from "common_components/textLoader/loader";
import { Avatar, Box } from "@mui/material";
import AgiraLogo from "asset/images/agirasurelogo.png";
import { useSelector } from "react-redux";

const TypingLine = ({ question, fact, isLatest,error }: any) => {
  const [displayFact, setDisplayFact] = useState("");
  const firstName = useSelector(
    (state: any) => state?.user?.userData?.first_name
  );
  const ref = useRef<HTMLDivElement>(null);
console.log(fact)
  useEffect(() => {
    let currentQuestion = "";
    let currentFact = "";
    let questionInterval: any, factInterval: any;

    const startFactTyping = () => {
      factInterval = setInterval(() => {
        if (currentFact?.length < fact?.length) {
          currentFact += fact[currentFact?.length];
          setDisplayFact(currentFact);
        } else {
          clearInterval(factInterval);
        }
      }, 10);
    };

    // Start typing animation only if it's the latest message
    if (isLatest) {
      startFactTyping();
    } else {
       setDisplayFact(fact)
    }

    return () => {
      clearInterval(questionInterval);
      clearInterval(factInterval);
    };
  }, [question, fact, isLatest]);

  React.useEffect(() => {
    if (displayFact.length && isLatest) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [displayFact.length, isLatest]);

  const htmlContent = <div dangerouslySetInnerHTML={{ __html: displayFact }} />;

  return (
    <Box sx={{ lineHeight: "30px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Avatar
          sx={{
            width: "25px",
            height: "25px",
            fontSize: "15px",
            color: "white",
          }}
        >
          {firstName?.charAt(0)}
        </Avatar>

        <span style={{ fontSize: "13px", fontWeight: 600 }}>You</span>
      </Box>
      <div style={{ marginBottom: "10px", marginLeft: "40px" }}>
        <div style={{fontSize:'18px'}}>{question}</div>
      </div>
      <Box>
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <img src={AgiraLogo} alt="agiraLogo" width="30px" height="30px" />
          <span style={{ fontWeight: 600, fontSize: "13px" }}>AgiraSure</span>
        </div>
        {error ? (
          <span
            style={{
              color: "red",
              // border: "1px solid red",
              padding: "0 35px",
              // background: "#f79992",
            }}
          >
            {error}
          </span>
        ) : displayFact ? (
          <div style={{ marginLeft: "40px", width: "90%",fontSize:'18px' }}>{htmlContent}</div>
        ) : (
          <Spinner />
        )}{" "}
        {isLatest && <div ref={ref} />}
      </Box>
    </Box>
  );
};

export default TypingLine;
